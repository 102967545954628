@media only screen and (max-width: 500px) {
  .notfound .notfound-404 h1 {
    font-size: 156px;
    top: 10%;
  }
  .notfound h2 {
    font-size: 30px;
  }
  .notfound p {
    font-size: 12px;
  }
  .notfound a {
    font-size: 10px;
    padding: 10px 38px;
  }

  .navbar-left,
  .navbar-brand {
    width: 100% !important;
    text-align: center;
  }

  .navbar-right {
    display: none !important;
  }

  .bg-login {
    background-image: url("assets/images/bg-login-mobile.png");
    background-size: cover;
  }
  .login-card {
    min-width: 0;
    width: 90%;
  }
  .login-title {
    padding-left: 10px;
    padding-right: 10px;
  }

  .app-content {
    height: calc(100vh - 100px);
  }

  .wide-screen {
    display: none !important;
  }
  .narrow-screen {
    display: flex !important;
    width: 100%;
    flex-flow: row;
  }

  .search-control {
    border-radius: 5px;
    min-height: 38px;
    padding: 10px;
    width: 100% !important;
  }
  .btn-module {
    border-radius: 5px;
    min-height: 38px;
    padding: 10px;
  }
  .dropdown-menu.limit-menu {
    top: 15px !important;
  }
  .dropdown-menu.limit-menu {
    top: 15px !important;
  }
  .dropdown-item.limit-item {
    padding: 1rem 1.5rem;
  }

  .module-card {
    box-shadow: rgba(16, 24, 40, 0.1) 0px 4px 6px -1px, rgba(16, 24, 40, 0.1) 0px 2px 4px -2px;
    margin-bottom: 16px;
  }
  .module-card-header {
    min-height: 80px;
    background-color: rgb(58, 147, 108);
    border-radius: 5px;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 0.5s ease 0s;
  }
  .module-card-title {
    color: rgb(255, 255, 255);
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 5px;
  }
  .module-card-subtitle {
    color: rgb(39, 42, 48);
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
  .module-card-pointer {
    cursor: pointer;
  }
  .module-card-pointer svg {
    color: rgb(244, 244, 246);
    min-height: 24px;
    min-width: 24px;
  }
  .module-card-body {
    background-color: rgb(255, 255, 255);
    border-radius: 0px 0px 5px 5px;
    transition: all 0.5s ease 0s;
    padding: 16px;
    display: none;
  }
  .module-card-body.active {
    display: block;
  }
  .module-card-action {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    row-gap: 12px;
    column-gap: 12px;
  }
  .module-card-action div {
    min-height: 34px;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    row-gap: 5px;
    column-gap: 5px;
  }
  .module-card-action svg,
  .module-card-action span {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: rgb(25, 27, 31);
  }
  .module-card-action-edit {
    background-color: rgb(206, 246, 216);
  }
  .module-card-action-detail {
    background-color: rgb(255, 249, 204);
  }
  .module-card-action-delete {
    background-color: rgb(255, 230, 213);
  }
  .btn-prevnex {
    border-radius: 5px;
    border: 1px solid #717986 !important;
    color: #717986 !important;
    cursor: pointer;
    margin-top: 10px !important;
    line-height: 30px !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
}
