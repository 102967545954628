@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,500&family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap");
@font-face {
  font-family: "Inter";
  src: url("assets/fonts/Inter-Regular.ttf");
}

:root {
  --orange-color: #fd7e14;
  --light-blue-color: #146dfd;
  --green-color: #295d49;
  --black-color: #003652;
  --grey-color: #e6e7ea;
  --red-color: #dc3545;

  --primary-color: #295d49;
  --danger-color: #dc3545;
  --light-color: #ffffff;
  --dark-color: #003652;

  font-size: 14px;
}

body {
  margin: 0;
  font-family: "Inter", sans-serif !important;
  font-size: 14px !important;
  color: var(--dark-color) !important;
  font-style: normal;
  overflow: hidden;
}
.container-fluid {
  background-color: var(--light-color);
}
.app-content {
  height: calc(100vh - 50px);
  overflow: hidden;
}
a:hover {
  text-decoration: none;
}

/* Login */
.bg-login {
  background-image: url("assets/images/bg-login.png");
  background-size: cover;
}
.login-card {
  box-shadow: 0px 4px 20px 1px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 20px;
  min-width: 400px;
  width: 441px;
}
.login-header {
  position: absolute;
  width: 138px;
}
.login-card-property {
  position: relative;
  bottom: 45px;
  left: 48px;
}
.login-top-property {
  position: absolute;
  width: 100vw;
  bottom: 190px;
  right: 0;
}
.login-bottom-property {
  position: absolute;
  width: 100vw;
  top: 370px;
  right: 0;
}
.login-slogan {
  width: 170px;
  margin-top: 5px;
}
.login-title {
  font-weight: 500;
  font-size: 18px;
  padding-left: 50px;
  padding-right: 50px;
  margin: 0px;
  color: #132b50;
  text-align: center;
}
.login-subtitle {
  font-size: 13px;
  text-align: center;
}
.login-failed {
  color: #34aee2;
  font-weight: 500;
  font-size: 12px;
}
.login-label {
  color: #132b50;
  font-size: 12px;
}

/* Navbar */
.navbar {
  background-color: var(--light-color);
  box-shadow: 0 1px 1px 1px rgba(18, 106, 211, 0.08);
}
.sidemenu-opener {
  width: 30px;
  height: 30px;
  text-align: center;
  margin-right: 5px;
  border-radius: 5px;
  padding-top: 8px;
  background-color: var(--light-color);
  box-shadow: 0 1px 1px 1px rgba(18, 106, 211, 0.18);
  cursor: pointer;
}
.sidemenu-opener.hidden {
  background-color: var(--light-color) !important;
}
.navbar-brand {
  padding: 0px;
}
.navbar-brand img {
  height: 30px !important;
  margin-left: 5px;
}
.notification {
  color: var(--dark-color);
  padding: 8px 0px;
  width: 35px;
  height: 35px;
  text-align: center;
  font-size: 18px;
  border-radius: 50%;
  box-shadow: 0px 0px 8px rgb(177 177 189 / 52%) !important;
}

/* Navbar Profile */
.user-profile {
  padding: 0px;
}
.user-name {
  font-weight: 500 !important;
  color: var(--dark-color);
}
.user-status {
  font-weight: 400 !important;
  font-size: 12px;
  color: rgba(91, 91, 91, 0.7);
}
.avatar {
  white-space: nowrap;
  background-color: #c3c3c3;
  border-radius: 50%;
  box-shadow: 0px 0px 8px rgb(177 177 189 / 52%) !important;
  position: relative;
  display: inline-flex;
}
.avatar img {
  border-radius: 50%;
  height: 35px;
  width: 35px;
}
.dropdown-toggle::after {
  color: var(--dark-color);
}
.dropdown-menu {
  border: 0;
  right: 10px;
  left: auto;
  top: 100%;
  padding: 0;
  box-shadow: 0px 4px 20px 1px rgba(0, 0, 0, 0.1);
  border-radius: 0px;
}
.dropdown-item {
  font-weight: 500;
  color: rgba(113, 121, 134);
  padding: 7px 10px;
}
.dropdown-icon {
  width: 14px;
  height: 14px;
  margin-right: 10px;
  color: var(--dark-color);
}
.dropdown-text {
  font-weight: 400 !important;
  font-size: 12px;
}
.logout {
  color: rgba(255, 40, 92, 1) !important;
  font-weight: 500 !important;
}

/* Sidebar */
.sidebar {
  height: 100%;
  display: none;
  overflow: auto;
  padding: 0px 0px 0px 0px;
  background-color: var(--light-color) !important;
  box-shadow: 0px 4px 20px -2px rgba(0, 0, 0, 0.1);
}
.sidebar.active {
  display: block;
}
.sidebar::-webkit-scrollbar {
  width: 5px;
}
.sidebar::-webkit-scrollbar-thumb {
  width: 5px;
  border-radius: 10px;
  background-color: var(--primary-color);
}
.sidebar-sticky .nav {
  min-height: calc(100vh - 115px);
}
.nav-arrow {
  width: 8px;
  height: 8px;
}
.nav-arrow.down {
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
}
.nav-link {
  font-weight: 500;
  color: hsla(217, 9%, 48%, 1) !important;
  padding: 10px 10px;
  cursor: pointer;
}
.nav-link.active {
  background-color: rgba(40, 167, 69, 0.15) !important;
}
.nav-link.active span,
.nav-link.active svg {
  color: #0b4963 !important;
}
.sidebar-icon {
  width: 14px;
  height: 14px;
  margin-right: 10px;
}
.sidebar-text {
  font-weight: 400 !important;
  font-size: 14px;
}
.nav-submenu .nav-item .nav-link {
  padding-left: 30px;
}

/* Forms */
.form-control {
  font-style: normal;
  font-weight: 400;
  font-size: 0.8rem !important;
  line-height: 1.5;
  border-radius: 0.25rem;
  width: 100%;
  padding: 0.4rem 1rem !important;
  height: calc(1em + 1.3rem) !important;
}
.form-control::placeholder,
.form-control:-ms-input-placeholder,
.form-control::-ms-input-placeholder {
  color: rgba(113, 121, 134, 0.75) !important;
  opacity: 1;
}
.form-group {
  margin-bottom: 11px !important;
}
.form-group .label {
  color: var(--dark-color);
  font-size: 12px;
  margin-bottom: 0.1rem !important;
}
.form-group .required-star {
  color: var(--danger-color);
}
.date-range {
  display: flex;
  padding: 5px 10px;
  border: 1px solid var(--grey-color);
  border-radius: 3px;
  background: var(--light-color);
  align-items: center;
  cursor: pointer;
}
.date-range > span {
  padding-left: 5px !important;
  padding-right: 5px;
  font-size: 0.8rem !important;
  color: #9c9c9c;
  font-weight: 500;
}
.date-container {
  top: 40px;
  left: auto;
  right: 0px;
  display: block;
  z-index: 9999 !important;

  position: absolute;
  border-radius: 4px;
  border: 1px solid var(--grey-color);
  padding: 0;
  margin: 0px;
  margin-top: 7px;
  background-color: var(--light-color);
}
.date-container:before {
  left: 360px;

  top: -7px;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #ccc;

  position: absolute;
  display: inline-block;
  content: "";
}
.rdrDateRangePickerWrapper {
  width: 100%;
}
.rdrCalendarWrapper {
  width: 100%;
}
.rdrMonthAndYearWrapper {
  padding-top: 0px !important;
  height: auto !important;
}
.rdrMonthName {
  padding: 0.2rem 0.85rem !important;
}
.rdrWeekDay {
  line-height: auto !important;
}
.rdrDay {
  height: 2.5rem !important;
}

.search-control {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: var(--dark-color);
  border-radius: 0.25rem;
  padding: 0.2rem 1rem !important;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
}
.search-control::placeholder,
.search-control:-ms-input-placeholder,
.search-control::-ms-input-placeholder {
  color: rgba(113, 121, 134, 0.75) !important;
  opacity: 1;
}
.number {
  width: 100px;
}
.notes {
  height: 100px !important;
}

/* Button */
.btn {
  text-align: center;
  display: inline-block;
  border-radius: 0.25rem;
  padding: 0.4rem 1.5rem;
  vertical-align: middle;
  display: inline-block;
  cursor: pointer;
  font-size: 12px !important;
  line-height: 16px;
}
.btn-block {
  width: 100% !important;
}
.btn-full {
  width: 100% !important;
}

.btn-primary {
  border: 1px solid var(--primary-color) !important;
  background-color: var(--primary-color) !important;
  color: var(--light-color);
}

.html5-qrcode-element {
  text-align: center;
  display: inline-block;
  border-radius: 0.25rem;
  padding: 0.4rem 1.5rem;
  vertical-align: middle;
  display: inline-block;
  cursor: pointer;
  font-size: 12px !important;
  line-height: 16px;
  border: 1px solid var(--primary-color) !important;
  background-color: var(--primary-color) !important;
  color: var(--light-color);
  margin: 3px 0px;
}

.btn-delete {
  border: 1px solid var(--danger-color) !important;
  background-color: var(--danger-color) !important;
  color: var(--light-color);
}
.btn-primary:hover {
  box-shadow: 0 5px 10px rgba(115, 108, 199, 0.4) !important;
}
.btn-primary-outline {
  border: 1px solid var(--primary-color) !important;
  background-color: transparent !important;
  color: var(--primary-color);
}
.btn-primary-outline:hover {
  box-shadow: 0 5px 10px rgba(115, 108, 199, 0.4) !important;
}
.btn-primary.dropdown-toggle::after {
  color: var(--light-color) !important;
}
.btn-signup {
  margin-top: auto;
  margin-bottom: auto;
  font-size: 14px !important;
  line-height: 18px;
}
.btn-module {
  padding: 0.2rem 1.5rem;
}
.btn-module span {
  margin-top: 0.25rem;
}
.btn-export {
  padding: 0.4rem 0.6rem;
}
.dropdown-toggle::after {
  margin-left: 0.555em;
  vertical-align: 0.155em;
}
.limit-menu {
  transform: translate3d(0px, 26px, 0px) !important;
  min-width: 75px !important;
}
.btn-prevnex,
.btn-apply {
  border: 1px solid var(--light-color) !important;
  padding: 0.2rem 1.5rem;
  background-color: var(--light-color) !important;
  color: var(--primary-color);
}
.btn-prevnex:hover {
  box-shadow: 0 5px 10px rgba(115, 108, 199, 0.4) !important;
  background-color: var(--grey-color) !important;
  color: var(--primary-color);
}
.btn-apply {
  padding: 0.2rem 0.5rem;
}
.btn-save,
.btn-back {
  cursor: pointer;
  margin-top: 3px;
  margin-bottom: 3px;
  padding: 0.2rem 1rem;
}
.btn-save:hover,
.btn-back:hover {
  box-shadow: 0 5px 10px rgba(115, 108, 199, 0.4) !important;
}
.btn-save {
  border: 1px solid var(--light-color) !important;
  background-color: var(--light-color) !important;
  color: var(--primary-color);
}
.btn-save:hover {
  background-color: var(--primary-color) !important;
  color: var(--light-color);
}
.btn-back {
  border: 1px solid var(--light-color) !important;
  background-color: var(--primary-color) !important;
  color: var(--light-color);
}
.btn-back:hover {
  background-color: var(--light-color) !important;
  color: var(--primary-color);
}

/* Switch */
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 22px;
  margin-left: 5px;
  margin-right: 10px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--grey-color);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
input:checked + .slider {
  background-color: var(--primary-color);
}
input:focus + .slider {
  box-shadow: 0 0 1px var(--primary-color);
}
input:checked + .slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}

/* Module */
.module {
  background-color: var(--grey-color);
  color: var(--primary-color);
  padding: 0.25rem 0.75rem;
  min-height: 57px;
}
.module-img {
  height: 1.5rem;
  margin-right: 10px;
}
.module-title {
  font-weight: 600;
  font-size: 20px;
  margin-top: 2px;
}
.content {
  padding: 20px;
}
.list-card-header {
  padding: 5px 22px !important;
  flex: 0 0 55px !important;
  display: flex;
  background: #fff;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px 5px 0 0;
}
.list-card-body {
  padding: 10px;
  background: #fff;
  border-radius: 0 0 5px 5px;
}
.main {
  height: 100%;
  padding: 0px;
  min-height: calc(100vh - 130px);
  overflow: scroll;
}
.narrow-screen {
  display: none !important;
}
.detail {
  border: 1px solid #eaeaea;
  margin: 0 0px;
  height: 100%;
  border-radius: 10px !important;
  box-shadow: 0px 1px 5px 1px rgba(69, 65, 78, 0.08);
}
.subTitle {
  background-color: var(--primary-color);
  color: var(--light-color);
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;

  padding: 0.75rem 1.25rem;
}
.content-form {
  padding: 20px;
}
/* Table */
.table-list td,
.table-list th {
  padding: 0.35rem;
  font-size: 12px;
  font-weight: 300;
  vertical-align: middle;
  color: var(--dark-color);
}

.table-list thead td,
.table-list thead th,
.table-list tfoot td,
.table-list tfoot th {
  background-color: var(--primary-color);
  font-size: 12px;
  font-weight: 600;
  color: #ffffff;
}

/* @media print {
  .print-table-footer {
    display: table-footer-group;
  }
} */

@media print {
  thead {
    display: table-header-group !important;
  }
  tbody:before {
    display: none;
  }
}

.table-list .header {
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center right;
}
.table-list .text-right {
  padding-right: 20px;
}
.table-list .sorting {
  background-image: url("assets/images/sort_both.png");
}
.table-list .sorting_asc {
  background-image: url("assets/images/sort_asc.png");
}
.table-list .sorting_desc {
  background-image: url("assets/images/sort_desc.png");
}

.table-list .data-item {
  cursor: pointer;
}
.table-card {
  font-family: "Arial";
  width: 100%;
}
.table-card td {
  vertical-align: top;
  padding-bottom: 5px;
}

.download-link {
  color: var(--dark-color);
}

.module-card {
  box-shadow: rgba(16, 24, 40, 0.1) 0px 4px 6px -1px,
    rgba(16, 24, 40, 0.1) 0px 2px 4px -2px;
  margin-bottom: 16px;
}
.module-card-header {
  min-height: 80px;
  background-color: rgb(58, 147, 108);
  border-radius: 5px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.5s ease 0s;
}
.module-card-title {
  color: rgb(255, 255, 255);
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 5px;
}
.module-card-subtitle {
  color: rgb(39, 42, 48);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.module-card-pointer {
  cursor: pointer;
}
.module-card-pointer svg {
  color: rgb(244, 244, 246);
  min-height: 24px;
  min-width: 24px;
}
.module-card-body {
  background-color: rgb(255, 255, 255);
  border-radius: 0px 0px 5px 5px;
  transition: all 0.5s ease 0s;
  padding: 16px;
  display: none;
}
.module-card-body.active {
  display: block;
}
.module-card-action {
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  row-gap: 12px;
  column-gap: 12px;
}
.module-card-action div {
  min-height: 34px;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  row-gap: 5px;
  column-gap: 5px;
}
.module-card-action svg,
.module-card-action span {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: rgb(25, 27, 31);
}
.module-card-action-edit {
  background-color: rgb(206, 246, 216);
}
.module-card-action-print {
  background-color: rgb(208, 225, 254);
}
.module-card-action-detail {
  background-color: rgb(255, 249, 204);
}
.module-card-action-delete {
  background-color: rgb(255, 230, 213);
}

.react-html5-camera-photo > img,
.react-html5-camera-photo > video {
  width: 100% !important;
}

.barcode-currentDate,
.barcode-img-hidden,
.hide-content,
.text-description {
  display: none;
}

.btn-selected {
  background-color: rgb(58, 147, 108);
}

/* Watermark */
.watermark { 
  width: 100%;
  height: 100%;
  position: absolute;
  display: block;
  transform: translateX(-50%);
  margin-top: 5%;
  left: 50%;
  z-index: 0;
}

.watermark h1 { 
  font-size: 240pt;
  transform: rotate(340deg);
  opacity: 15%;
  text-transform: uppercase;
}